<template>
  <div class="card-form">
    <div class="card-list">
      <Card
        :fields="fields"
        :labels="formData"
        :isCardNumberMasked="isCardNumberMasked"
        :randomBackgrounds="randomBackgrounds"
        :backgroundImage="backgroundImage"
      />
    </div>
    <div class="card-form__inner">
      <div class="card-input">
        <label for="cardNumber" class="card-input__label">
          <app-i18n code="entities.unlocktest.form.cardnumber"></app-i18n>
        </label>
        <input type="tel"
          :id="fields.cardNumber"
          @input="changeNumber"
          @focus="focusCardNumber"
          @blur="blurCardNumber"
          class="card-input__input"
          :value="formData.cardNumber"
          :maxlength="cardNumberMaxLength"
          data-card-field
          autocomplete="off"
          @click="invalidcardalert = false"
        />
        <button
          class="card-input__eye"
          :class="{ '-active' : !isCardNumberMasked }"
          title="Show/Hide card number"
          tabindex="-1"
          :disabled="formData.cardNumber === ''"
          @click="toggleMask"
        ></button>
      </div>
      <div class="card-input">
        <label for="cardName" class="card-input__label">
          <app-i18n code="entities.unlocktest.form.fullname"></app-i18n>
        </label>
        <input
          type="text"
          :id="fields.cardName"
          v-letter-only
          @input="changeName"
          class="card-input__input"
          :value="formData.cardName"
          data-card-field
          autocomplete="off"
        />
      </div>
      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">
              <app-i18n code="entities.unlocktest.form.expirationdate"></app-i18n>
            </label>
            <select class="card-input__input -select"
              :id="fields.cardMonth"
              v-model="formData.cardMonth"
              data-card-field
            >
              <option value disabled selected>
                <app-i18n code="entities.unlocktest.form.month"></app-i18n>
              </option>
              <option
                v-bind:value="n < 10 ? '0' + n : n"
                v-for="n in 12"
                v-bind:disabled="n < minCardMonth"
                v-bind:key="n"
              >{{generateMonthValue(n)}}</option>
            </select>
            <select
              class="card-input__input -select"
              :id="fields.cardYear"
              v-model="formData.cardYear"
              data-card-field
            >
              <option value disabled selected>
                <app-i18n code="entities.unlocktest.form.year"></app-i18n>
              </option>
              <option
                v-bind:value="$index + minCardYear"
                v-for="(n, $index) in 12"
                v-bind:key="n"
              >{{$index + minCardYear}}</option>
            </select>
          </div>
        </div>
        <div class="card-form__col -cvv">
          <div class="card-input">
            <label for="cardCvv" class="card-input__label">CVV</label>
            <input type="tel"
              class="card-input__input"
              v-number-only
              :id="fields.cardCvv"
              maxlength="4"
              :value="formData.cardCvv"
              @input="changeCvv"
              data-card-field
              autocomplete="off"
            />
          </div>
        </div>
      </div>

     <transition name="fade">
        <div class="alert" v-if="invalidcardalert">
          <span class="closebtn" onclick="this.parentElement.style.display='none';" @click="invalidcardalert = false" >&times;</span>
          <app-i18n code="entities.unlocktest.form.invalidCardNumber"></app-i18n>
        </div>
      </transition>

      <slot></slot>
      <div class="card-form__button_area">
        <button class="card-form__button" @click="doPay">
          <app-i18n code="entities.unlocktest.form.submit"></app-i18n>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card';
export default {
  name: 'CardForm',
  directives: {
    'number-only': {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(
            /[^0-9]/g,
            '',
          );
          if (
            event.charCode >= 48 &&
            event.charCode <= 57
          ) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener('keypress', checkValue);
      },
    },
    'letter-only': {
      bind(el) {
        function checkValue(event) {
          if (
            event.charCode >= 48 &&
            event.charCode <= 57
          ) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener('keypress', checkValue);
      },
    },
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          cardName: '',
          cardNumber: '',
          cardNumberNotMask: '',
          cardMonth: '',
          cardYear: '',
          cardCvv: '',
        };
      },
    },
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Card,
  },
  data() {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv',
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
      invalidcardalert:false,
    };
  },
  computed: {
    minCardMonth() {
      if (this.formData.cardYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
  },
  watch: {
    cardYear() {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = '';
      }
    },
  },
  mounted() {
    this.maskCardNumber();
  },
  methods: {
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    changeName(e) {
      this.formData.cardName = e.target.value;
    },
    changeNumber(e) {
      this.formData.cardNumber = e.target.value;
      let value = this.formData.cardNumber.replace(
        /\D/g,
        '',
      );
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.formData.cardNumber = value
          .replace(/(\d{4})/, '$1 ')
          .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        this.cardNumberMaxLength = 17;
      } else if (
        /^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)
      ) {
        // diner's club, 14 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, '$1 ')
          .replace(/(\d{4}) (\d{6})/, '$1 $2 ');
        this.cardNumberMaxLength = 16;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, '$1 ')
          .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
          .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
        this.cardNumberMaxLength = 19;
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == 'deleteContentBackward') {
        let lastChar = this.formData.cardNumber.substring(
          this.formData.cardNumber.length,
          this.formData.cardNumber.length - 1,
        );
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') {
          this.formData.cardNumber = this.formData.cardNumber.substring(
            0,
            this.formData.cardNumber.length - 1,
          );
        }
      }
    },
    changeCvv(e) {
      this.formData.cardCvv = e.target.value;
    },
    doPay() {
      if(this.checkCardValidation()){
        this.$emit('pay', this.formData);
      }
    },
    checkCardValidation(){
      let number = this.formData.cardNumberNotMask.replace(
              / /g,
              '',
      );
      var sum = 0;
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1));
        if (i % 2 === 0) {
          intVal *= 2;
          if (intVal > 9) {
            intVal = 1 + (intVal % 10);
          }
        }
        sum += intVal;
      }
      if ((sum === 0) || (sum % 10 !== 0)) {
        this.invalidcardalert = true;
        return false;
      } else {
        return true;
      }
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      }
    },
    maskCardNumber() {
      this.formData.cardNumberNotMask = this.formData.cardNumber;
      this.mainCardNumber = this.formData.cardNumber;
      let arr = this.formData.cardNumber.split('');
      arr.forEach((element, index) => {
        if (
          index > 4 &&
          index < 14 &&
          element.trim() !== ''
        ) {
          arr[index] = '*';
        }
      });
      this.formData.cardNumber = arr.join('');
    },
    unMaskCardNumber() {
      this.formData.cardNumber = this.mainCardNumber;
    },
    focusCardNumber() {
      this.unMaskCardNumber();
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked;
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      } else {
        this.unMaskCardNumber();
      }
    },
  },
};
</script>
<style scoped>
  @import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');
  body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
  }

  * {
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }
  .wrapper {
    min-height: 60vh;
    display: flex;
    padding: 30px 15px;
  }
  @media screen and (max-width: 700px), (max-height: 500px) {
    .wrapper {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }


.alert {
  margin: 1rem 0;
  padding: 8px 20px 8px 20px;
  background-color: #a03749;
  color: white;
  border-radius: 15px;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: black;
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-leave-active {
  transition: opacity 1s;
  opacity: 0;
}
/*#----------------------*/
.card-list {
  margin-bottom: -130px;
}
@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}

.card-form {
  max-width: 470px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 1.5rem;
  padding-top: 165px;
}
@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 165px;
  }
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 165px;
  }
}
.card-form__row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 480px) {
  .card-form__row {
    flex-wrap: wrap;
  }
}
.card-form__col {
  flex: auto;
  margin-right: 35px;
}
.card-form__col:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
  .card-form__col {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
  .card-form__col:last-child {
    margin-bottom: 0;
  }
}
.card-form__col.-cvv {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__col.-cvv {
    max-width: initial;
  }
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button_area {
  width: 100%;
  text-align: center;
}
.card-form__button {
  width: 30%;
  height: 36px;
  background: #A51E36;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 1rem;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}

/* ############################# */
.card-input {
  margin-bottom: 10px;
  position: relative;
}
.card-input__label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #1a3b5d;
  width: 100%;
  display: block;
  user-select: none;
}
.card-input__input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: 'Source Sans Pro', sans-serif;
}
.card-input__input:hover,
.card-input__input:focus {
  border-color: #3d9cff;
}
.card-input__input:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.card-input__eye {
  display: inline-flex;
  position: absolute;
  width: 1em;
  height: 1em;
  font-size: 22px;
  border-radius: 50%;
  top: 32px;
  right: 10px;
  opacity: 0.75;
  color: #8c9cae;
  cursor: pointer;
  padding: 0;
  background: none;
  display: inline-flex;
  border: 2px solid currentColor;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}
.card-input__eye:before {
  content: '';
  position: absolute;
  background: white;
  width: 0.35em;
  height: 0.35em;
  top: 5px;
  left: 5px;
  z-index: 2;
  border-radius: 50%;
  transform: scale(0.1);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.1s;
}
.card-input__eye:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  background: currentColor;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  transform: scale(0.1);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.card-input__eye:hover:not(:disabled),
.card-input__eye.-active:not(:disabled) {
  color: #2364d2;
  opacity: 1;
}
.card-input__eye.-active::before,
.card-input__eye.-active::after {
  transform: scale(1);
  opacity: 1;
}
.card-input__eye:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}

.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}

.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
</style>