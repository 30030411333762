<template>
  <div class="card-item" :class="{ '-active' : isCardFlipped }">
    <div class="card-item__side -front">
      <div
        class="card-item__focus"
        :class="{'-active' : focusElementStyle }"
        :style="focusElementStyle"
        ref="focusElement"
      ></div>
      <div class="card-item__cover">
        <img
          v-if="currentCardBackground"
          :src="currentCardBackground"
          class="card-item__bg"
        />
      </div>
      <div class="card-item__wrapper">
        <div class="card-item__top">
          <img
            src="/images/cardimg/chip.png"
            class="card-item__chip"
          />
          <div class="card-item__type">
            <transition name="slide-fade-up">
              <img
                :src="'/images/cardimg/' + cardType + '.png'"
                v-if="cardType"
                :key="cardType"
                alt
                class="card-item__typeImg"
              />
            </transition>
          </div>
        </div>
        <label :for="fields.cardNumber" class="card-item__number" :ref="fields.cardNumber">
          <template>
            <span v-for="(n, $index) in currentPlaceholder" :key="$index">
              <transition name="slide-fade-up">
                <div class="card-item__numberItem" v-if="getIsNumberMasked($index, n)">*</div>
                <div
                  class="card-item__numberItem"
                  :class="{ '-active' : n.trim() === '' }"
                  :key="currentPlaceholder"
                  v-else-if="labels.cardNumber.length > $index"
                >{{labels.cardNumber[$index]}}</div>
                <div
                  class="card-item__numberItem"
                  :class="{ '-active' : n.trim() === '' }"
                  v-else
                  :key="currentPlaceholder + 1"
                >{{n}}</div>
              </transition>
            </span>
          </template>
        </label>
        <div class="card-item__content">
          <label :for="fields.cardName" class="card-item__info" :ref="fields.cardName">
            <div class="card-item__holder"><app-i18n code="entities.unlocktest.card.cardholder"></app-i18n></div>
            <transition name="slide-fade-up">
              <div class="card-item__name" v-if="labels.cardName.length" key="1">
                <transition-group name="slide-fade-right">
                  <span
                    class="card-item__nameItem"
                    v-for="(n, $index) in labels.cardName.replace(/\s\s+/g, ' ')"
                    :key="$index + 1"
                  >{{n}}</span>
                </transition-group>
              </div>
              <div class="card-item__name" v-else key="2"><app-i18n code="entities.unlocktest.card.fullname"></app-i18n></div>
            </transition>
          </label>
          <div class="card-item__date" ref="cardDate">
            <label :for="fields.cardMonth" class="card-item__dateTitle"><app-i18n code="entities.unlocktest.card.expires"></app-i18n></label>
            <label :for="fields.cardMonth" class="card-item__dateItem">
              <transition name="slide-fade-up">
                <span v-if="labels.cardMonth" :key="labels.cardMonth">{{labels.cardMonth}}</span>
                <span v-else key="2">MM</span>
              </transition>
            </label>
            /
            <label for="cardYear" class="card-item__dateItem">
              <transition name="slide-fade-up">
                <span v-if="labels.cardYear" :key="labels.cardYear">{{String(labels.cardYear).slice(2,4)}}</span>
                <span v-else key="2">YY</span>
              </transition>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="card-item__side -back">
      <div class="card-item__cover">
        <img
          v-if="currentCardBackground"
          :src="currentCardBackground"
          class="card-item__bg"
        />
      </div>
      <div class="card-item__band"></div>
      <div class="card-item__cvv">
        <div class="card-item__cvvTitle">CVV</div>
        <div class="card-item__cvvBand">
          <span v-for="(n, $index) in labels.cardCvv" :key="$index">*</span>
        </div>
        <div class="card-item__type">
          <img
            :src="'/images/cardimg/' + cardType + '.png'"
            v-if="cardType"
            class="card-item__typeImg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    labels: Object,
    fields: Object,
    isCardNumberMasked: Boolean,
    randomBackgrounds: {
      type: Boolean,
      default: true
    },
    backgroundImage: [String, Object]
  },
  data () {
    return {
      focusElementStyle: null,
      currentFocus: null,
      isFocused: false,
      isCardFlipped: false,
      amexCardPlaceholder: '#### ###### #####',
      dinersCardPlaceholder: '#### ###### ####',
      defaultCardPlaceholder: '#### #### #### ####',
      currentPlaceholder: ''
    }
  },
  watch: {
    currentFocus () {
      if (this.currentFocus) {
        this.changeFocus()
      } else {
        this.focusElementStyle = null
      }
    },
    cardType () {
      this.changePlaceholder()
    }
  },
  mounted () {
    this.changePlaceholder()

    let self = this
    let fields = document.querySelectorAll('[data-card-field]')
    fields.forEach(element => {
      element.addEventListener('focus', () => {
        this.isFocused = true
        if (element.id === this.fields.cardYear || element.id === this.fields.cardMonth) {
          this.currentFocus = 'cardDate'
        } else {
          this.currentFocus = element.id
        }
        this.isCardFlipped = element.id === this.fields.cardCvv
      })
      element.addEventListener('blur', () => {
        this.isCardFlipped = !element.id === this.fields.cardCvv
        setTimeout(() => {
          if (!self.isFocused) {
            self.currentFocus = null
          }
        }, 300)
        self.isFocused = false
      })
    })
  },
  computed: {
    cardType () {
      let number = this.labels.cardNumber
      let re = new RegExp('^4')
      if (number.match(re) != null) return 'visa'

      re = new RegExp('^(34|37)')
      if (number.match(re) != null) return 'amex'

      re = new RegExp('^5[1-5]')
      if (number.match(re) != null) return 'mastercard'

      re = new RegExp('^6011')
      if (number.match(re) != null) return 'discover'

      re = new RegExp('^62')
      if (number.match(re) != null) return 'unionpay'

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy'

      re = new RegExp('^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}')
      if (number.match(re) != null) return 'dinersclub'

      re = new RegExp('^35(2[89]|[3-8])')
      if (number.match(re) != null) return 'jcb'

      return '' // default type
    },
    currentCardBackground () {
      if (this.randomBackgrounds && !this.backgroundImage) { // TODO will be optimized
        let random = Math.floor(Math.random() * 4 + 1)
        return `/images/cardimg/${random}.jpeg`
      } else if (this.backgroundImage) {
        return this.backgroundImage
      } else {
        return null
      }
    }
  },
  methods: {
    changeFocus () {
      let target = this.$refs[this.currentFocus]
      this.focusElementStyle = target ? {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
      } : null
    },
    getIsNumberMasked (index, n) {
      return index > 4 && index < 14 && this.labels.cardNumber.length > index && n.trim() !== '' && this.isCardNumberMasked
    },
    changePlaceholder () {
      if (this.cardType === 'amex') {
        this.currentPlaceholder = this.amexCardPlaceholder
      } else if (this.cardType === 'dinersclub') {
        this.currentPlaceholder = this.dinersCardPlaceholder
      } else {
        this.currentPlaceholder = this.defaultCardPlaceholder
      }
      this.$nextTick(() => {
        this.changeFocus()
      })
    }
  }
}
</script>
<style scoped>
  /* ################################# */
  .card-item {
    max-width: 330px;
    height: 195px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    width: 100%;
  }

  .card-item.-active .card-item__side.-front {
    transform: perspective(1000px) rotateY(180deg)
    rotateX(0deg) rotateZ(0deg);
  }
  .card-item.-active .card-item__side.-back {
    transform: perspective(1000px) rotateY(0) rotateX(0deg)
    rotateZ(0deg);
  }
  .card-item__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);
  }
  .card-item__focus:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #08142f;
    height: 100%;
    border-radius: 5px;
    filter: blur(25px);
    opacity: 0.5;
  }
  .card-item__focus.-active {
    opacity: 1;
  }
  .card-item__side {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg)
    rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;
  }
  .card-item__side.-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: perspective(2000px) rotateY(-180deg)
    rotateX(0deg) rotate(0deg);
    z-index: 2;
    padding: 0;
    height: 100%;
  }
  .card-item__side.-back .card-item__cover {
    transform: rotateY(-180deg);
  }
  .card-item__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    /* object-fit: cover; */
  }
  .card-item__cover {
    height: 100%;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    background-image: linear-gradient(
            147deg,
            #354fce 0%,
            #0c296b 74%
    );
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
  }
  .card-item__cover:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(6, 2, 29, 0.45);
  }
  .card-item__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 10px;
  }
  .card-item__chip {
    width: 33px;
  }
  .card-item__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;
  }
  .card-item__typeImg {
    max-width: 70%;
    object-fit: contain;
    max-height: 70%;
    object-position: top right;
  }
  .card-item__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 5px 8px;
    font-weight: 500;
    display: block;
    cursor: pointer;
  }
  .card-item__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
  }
  .card-item__wrapper {
    font-family: 'Source Code Pro', monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
  }
  .card-item__name {
    font-size: 14px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
  .card-item__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }
  .card-item__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 21px;
    margin-bottom: 11px;
    display: inline-block;
    padding: 5px 8px;
    cursor: pointer;
  }
  .card-item__numberItem {
    width: 12px;
    display: inline-block;
  }
  .card-item__numberItem.-active {
    width: 20px;
  }
  @media screen and (max-width: 480px) {
    .card-item__numberItem {
      width: 13px;
    }
    .card-item__numberItem.-active {
      width: 16px;
    }
  }
  @media screen and (max-width: 360px) {
    .card-item__numberItem {
      width: 12px;
    }
    .card-item__numberItem.-active {
      width: 8px;
    }
  }
  .card-item__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }
  .card-item__date {
    flex-wrap: wrap;
    font-size: 14px;
    margin-left: auto;
    padding: 5px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    .card-item__date {
      font-size: 16px;
    }
  }
  .card-item__dateItem {
    position: relative;
  }
  .card-item__dateItem span {
    width: 22px;
    display: inline-block;
  }
  .card-item__dateTitle {
    opacity: 0.7;
    font-size: 12px;
    padding-bottom: 6px;
    width: 100%;
  }
  .card-item__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 20px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
  }
  .card-item__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 5px;
  }
  .card-item__cvv .card-item__type {
    opacity: 0.7;
  }
  .card-item__cvvTitle {
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  .card-item__cvvBand {
    height: 25px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
  }

</style>