<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/tests' }">
        <app-i18n code="entities.exam.menuTests"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.order.new.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrapper" id="app">
      <CardForm @pay="doUnlock" >
        <div class="demo__items">
          <div class="item">
            <div class="item__top">
              <h2 class="item__heading">
                {{exam.name}}
                <i class="item__amount">- {{exam.price}} TL</i>
              </h2>
            </div>
          </div>
        </div>
      </CardForm>
    </div>
    <el-dialog
            title="Unlocking Exam..."
            :visible.sync="dialogVisible"
            :before-close="handleClose"
            center>
      <div class="app-page-spinner" v-if="!htmlContentVisible && dialogVisible" v-loading="!htmlContentVisible && dialogVisible"></div>
      <iframe v-if="htmlContentVisible" :srcDoc="htmlContent" style="width: 100%; height: 400px; border: 0px;"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import CardForm from '../components/CardForm';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { i18n } from '@/i18n';
export default {
  name: 'app',
  props: ['id'],
  components: {
    CardForm,
  },
  data() {
    return {
      exam: {
        id: this.id
      },
    };
  },
  async mounted() {
    if(this.rows.length > 0){
      this.exam = this.rows.filter(r => r.id === this.id)[0];
    }else{
      await this.doFind(this.id);
      this.exam = this.record;
    }
  },
  computed: {
    ...mapGetters({
      rows: 'exam/list/rows',
      record: 'exam/view/record',
      htmlContent: 'order/form/htmlContent',
      dialogVisible: 'order/form/dialogVisible',
      htmlContentVisible: 'order/form/htmlContentVisible',
    }),
  },
  methods: {
    ...mapMutations({
      setDialogVisible: 'order/form/SET_DIALOG_VISIBLE'
    }),
    ...mapActions({
      doFind: 'exam/view/doFind',
      doStartOrder: 'order/form/doStartOrder',
    }),
    doUnlock(formData) {
      formData.amount = this.exam.price;
      formData.exams = [this.exam.id];
      this.setDialogVisible(true);
      this.doStartOrder(formData);
    },
    handleClose() {
      this.$confirm(i18n('common.areYouSure'))
              .then(() => {
                this.setDialogVisible(false);
                //done();
              })
              .catch(() => {});
    }
  },
};
</script>

<style>
  .el-dialog__title{
    color: #8f1a2f;
  }
</style>

<style scoped>
.demo__items {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  overflow: hidden;
  z-index: 3;
  position: relative;
  text-align: center;
}

.item {
  margin: 0.2rem 0;
  border-radius: 0.1rem;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  background: #fff;
  transform-style: preserve-3d;
}

.item__top {
  position: relative;
  padding: 5px 30px;
}

.item__heading {
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #A51E36;
  font-weight: 600;
}

.item__amount {
  font-style: normal !important;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: black;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
} 
</style>